// 专项考试列表
<template>
  <div class="pageContol listWrap templateList specialTestList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">专项考试</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div
              title="考试名称"
              class="searchboxItem ci-full"
              style="width: 350px"
            >
              <span class="itemLabel">考试名称:</span>
              <el-input
                v-model="examName"
                type="text"
                size="small"
                clearable
                placeholder="请输入考试名称"
              />
            </div>
            <div
              title="行政区划"
              class="searchboxItem ci-full"
              style="width: 350px"
            >
              <span class="itemLabel" style="width: 6rem">行政区划:</span>
              <el-cascader
                :props="{ emitPath: false, checkStrictly: true }"
                v-model="areaId"
                :options="cityTree"
                clearable
                filterable
                size="small"
                style="width: 100%"
              ></el-cascader>
            </div>
            <div
              title="考试类型"
              class="searchboxItem ci-full"
              style="width: 350px"
            >
              <span class="itemLabel">考试类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                @clearParams="clearParams"
                modal
                type
                typeStu
                size="small"
              />
            </div>
          </div>
          <div class="searchbox" style="padding-top: 10px">
            <div
              title="开始时间"
              class="searchboxItem ci-full"
              style="width: 350px"
            >
              <span class="itemLabel">开始时间:</span>
              <el-date-picker
                size="small"
                v-model="startTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
            <div
              title="结束时间"
              class="searchboxItem ci-full"
              style="width: 350px"
            >
              <span class="itemLabel">结束时间:</span>
              <el-date-picker
                size="small"
                v-model="endTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
            <div
              title="创建时间"
              class="searchboxItem ci-full"
              style="width: 350px"
            >
              <span class="itemLabel">创建时间:</span>
              <el-date-picker
                size="small"
                v-model="createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="searchbox" style="padding-top: 10px">
            <div
              title="考试状态"
              class="searchboxItem ci-full"
              style="width: 350px"
            >
              <span class="itemLabel">考试状态:</span>
              <el-select
                clearable
                size="small"
                v-model="examState"
                placeholder="请选择考试状态"
                style="width: 100%"
              >
                <el-option
                  v-for="item in examStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="btnBox" style="margin-left: 20px">
              <el-button
                style="margin-left: 20px"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" round @click="handleEdit('add')"
                >新建</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="考试名称"
                align="left"
                prop="examName"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="行政区划"
                align="left"
                prop="areaNamePath"
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                label="考试类型"
                align="left"
                prop="categoryName"
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                label="开始时间"
                align="left"
                prop="startDate"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="结束时间"
                align="center"
                show-overflow-tooltip
                prop="endDate"
                min-width="150"
              >
              </el-table-column>
              <el-table-column
                label="创建时间"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                min-width="150"
              />
              <el-table-column
                label="考试状态"
                align="left"
                prop="compName"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("EXAM_STATE", scope.row.examState) }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="300px"
                fixed="right"
              >
                <div slot-scope="scope" class="df">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleEdit('edit', scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="lookcode(scope.row)"
                    >考试二维码</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleDetail(scope.row.examId)"
                    >考试详情</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleExamRecord(scope.row.examId)"
                    >考试记录</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    v-if="
                      scope.row.publicState == '20' &&
                      scope.row.examState != '30'
                    "
                    @click="
                      handleExport(
                        scope.row.examId,
                        scope.row.examAuthentication
                      )
                    "
                    >导入考生</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="考试二维码"
      :visible.sync="DialogPicShow"
      width="30%"
      center
      top="3%"
      :before-close="doClose"
    >
      <div class="pic_content flexdcc" ref="pic_content">
        <h2>{{ examNameDialog }}考试</h2>
        <div style="width: 100%; margin-bottom: 15px">
          <p>1、考试开始时间：{{ examStartTime }}</p>
          <p>2、考试结束时间：{{ examEndTime }}</p>
          <p>
            3、考试限时：{{
              examTime == "10" ? examTimeValue + "分钟" : "无限时"
            }}
          </p>
          <p>4、考试地点：安知APP-专项考试</p>
          <p>
            5、考试限次：{{ examNum == "20" ? "无限次" : examNumValue + "次" }}
          </p>
          <p>
            6、满分{{ totalScore }}分，及格分{{ passScore }}分，共{{
              questionNum
            }}道题
          </p>
          <p v-if="examAuthentication == '10'">
            7、本场考试要求实名认证，请提前在安知APP上完成实名认证。
          </p>
        </div>
        <div
          style="width: 100%; margin-bottom: 20px"
          class="df"
          v-if="examDescription"
        >
          <p>考试说明：</p>
          <p class="flex1" v-html="examDescription">{{ examDescription }}</p>
        </div>
        <div style="width: 100%" class="flexdcc">
          <div class="pic_box">
            <div id="qrcode" ref="qrcode"></div>
          </div>
          <p style="color: #5c6be8; font-size: 18px; font-weight: 600">
            扫码参加考试
          </p>
        </div>
      </div>
      <div class="flexdcc">
        <el-button class="bgc-bv" @click="downloadPic" round
          >下载图片</el-button
        >
      </div>
    </el-dialog>
    <!--  -->
    <el-dialog
      title="指定学员导入"
      :visible.sync="centerDialogVisible"
      width="50%"
      center
      :before-close="doCloseload"
      class="exportdialog"
    >
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>下载并填写导入模板</span>
          </h1>
          <div class="df">
            <span
              @click="downloadExcel(1)"
              v-if="exportTempStu == '10'"
              style="
                margin-top: 20px;
                color: #409eff;
                cursor: pointer;
                margin-right: 20px;
              "
              >实名考试模板</span
            >
            <span
              @click="downloadExcel(2)"
              v-else
              style="margin-top: 20px; color: #409eff; cursor: pointer"
              >非实名考试模板</span
            >
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入编写好的Excel文档</span>
          </h1>
          <div class="flexdc">
            <div class="df" style="margin: 20px 0 10px">
              <el-upload
                class="upload-demo upload-workers"
                :action="actionUrl"
                :on-error="handleError"
                :on-success="handleSuccess"
                :on-change="uploadChange"
                :show-file-list="false"
                :auto-upload="false"
              >
                <el-button class="bgc-bv" style="font-size: 12px; width: 80%"
                  >浏览</el-button
                >
              </el-upload>
              <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
                当前选择文件：
                <span style="color: #f46173">{{ fileName }}</span>
              </p>
              <p v-else style="margin-top: 10px; margin-left: 10px">
                未选择文件
              </p>
            </div>
            <div>
              <el-button
                class="bgc-bv"
                size="mini"
                style="margin-top: 10px; height: 35px"
                @click="doExport"
                >开始导入</el-button
              >
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum">
            <span>学员总数量:{{ totalNum }}人;</span>
            <span>成功:{{ correctNum }}人;</span>
            <span>失败:{{ errorNum }}人;</span>
          </div>
          <div>
            <el-button
              class="bgc-bv"
              style="margin-top: 15px; height: 35px"
              size="mini"
              :disabled="errorNum == '0'"
              @click="doExportError"
              >导出错误数据</el-button
            >
          </div>
          <div style="margin-top: 15px; color: #dd1d35" v-if="errorNum != 0">
            有导入失败学员，请导出错误数据，将导入失败学员调整后重新上传
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import QRCode from "qrcodejs2"; // 引入qrcode
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import tree from "@/components/treePopup";
import List from "@/mixins/List";
import html2canvas from "html2canvas";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "specialTestList",
  components: {
    Empty,
    PageNum,
    tree
  },
  mixins: [List],
  data() {
    return {
      qrcodeUrl: "",
      DialogPicShow: false,
      //
      areaId: "",
      examName: "",
      examState: "",
      startTime: "",
      endTime: "",
      createTime: "",
      //
      cityTree: [], // 行政区划
      examStateList: [], //
      ruleForm: {
        Trainingtype: "",
      },
      params: {},
      // 导学员参数
      totalNum: "0",
      errorNum: "0",
      correctNum: "0",
      fileName: "", // 导入学员文件名
      fileKey: "", // 导入学员文件的id
      centerDialogVisible: false,
      // 考试二维码
      examStartTime: "",
      examEndTime: "",
      examTime: "",
      examTimeValue: "",
      examAddress: "",
      examNum: "",
      examNumValue: "",
      totalScore: "",
      passScore: "",
      examAuthentication: "",
      examDescription: "",
      examNameDialog: "",
      questionNum: "",
      exportTempStu: "10",
    };
  },
  computed: {},
  created() {
    // console.log(this.$route);
    if (this.$route.params.isExport == 1) {
      this.handleExport(
        this.$route.params.examId,
        this.$route.params.exportTempStu
      );
    }
    this.getcity();
    this.getSelect();
    this.getTableHeight();
  },
  methods: {
    // 培训类型回调
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    // 获取字典 -- 考试状态
    getSelect() {
      const listObj = this.$setDictionary("EXAM_STATE", "list");
      this.examStateList = [];
      for (const key in listObj) {
        this.examStateList.push({
          value: key,
          label: listObj[key],
        });
      }
    },
    // 考试二维码
    lookcode(row) {
      ;
      this.examNameDialog = row.examName;
      this.codePic = [];
      this.$post("/biz/exam/queryExamRCode", { examId: row.examId }).then(
        (res) => {
          ;
          if (res.status == "0") {
            this.examStartTime = res.data.startDate;
            this.examEndTime = res.data.endDate;
            this.examTime = res.data.handoverTime;
            this.examNum = res.data.answerNum;
            this.totalScore = res.data.totalScore;
            this.passScore = res.data.passScore;
            this.examAuthentication = res.data.examAuthentication;
            this.questionNum = res.data.questionNum;
            if (res.data.answerNum == "10") {
              this.examNumValue = res.data.answerNumValue;
            }
            if (res.data.handoverTime == "10") {
              this.examTimeValue = res.data.handoverTimeValue;
            }
            if (res.data.examDescription) {
              this.examDescription = res.data.examDescription.replace(
                /sunrealbr/g,
                "</br>"
              );
            }
            this.DialogPicShow = true;
            this.qrcodeUrl = res.data.codeImgUrl;
            // 使用$nextTick确保数据渲染
            this.$nextTick(() => {
              this.crateQrcode();
            });
          }
        }
      );
    },
    // 生成二维码
    crateQrcode() {
      this.$refs.qrcode.innerHTML = "";
      this.qr = new QRCode("qrcode", {
        width: 130,
        height: 130,
        text: this.qrcodeUrl, // 二维码内容
        render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
      });
    },
    // 下载图片
    downloadPic() {
      html2canvas(this.$refs.pic_content).then((res) => {
        let link = document.createElement("a");
        link.href = res.toDataURL();
        link.setAttribute("download", "考试二维码.jpg");
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
      });
    },
    doClose() {
      this.DialogPicShow = false;
      this.$refs.qrcode.innerHTML = "";
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.examName) {
        params.examName = this.examName;
      }
      if (this.examState) {
        params.examState = this.examState;
      }
      if (this.startTime && this.startTime.length) {
        params.startStartDate = this.startTime[0];
        params.startEndDate = this.startTime[1];
      }
      if (this.endTime && this.endTime.length) {
        params.endStartDate = this.endTime[0];
        params.endEndDate = this.endTime[1];
      }
      if (this.createTime && this.createTime.length) {
        params.startCreateTime = this.createTime[0];
        params.endCreateTime = this.createTime[1];
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/biz/exam/pageList",
        params,
        pageNum,
      });
    },
    // 修改
    handleEdit(stu, row) {
      if (stu == "edit") {
        this.$router.push({
          path: "/web/specialTestEdit",
          query: {
            stu: stu,
            examId: row.examId,
            examStu: row.examState,
          },
        });
      } else {
        this.$router.push({
          path: "/web/specialTestEdit",
          query: {
            stu: stu,
          },
        });
      }
    },
    handleDetail(examId) {
      this.$router.push({
        path: "/web/specialTestDetail",
        query: {
          examId,
        },
      });
    },
    handleExamRecord(examId) {
      this.$router.push({
        path: "/web/specialTestRecordList",
        query: {
          examId,
        },
      });
    },
    /**
     *  行政区划
     */
    getcity() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.cityTree = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    //
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    // 导入学员
    handleExport(id, exportTempStu) {
      this.exportTempStu = exportTempStu;
      this.centerDialogVisible = true;
      this.examId = id;
    },
    /**
     * 下载模板
     */
    downloadExcel(stu) {
      let link = document.createElement("a");
      let urlDown = stu == 1 ? "real_name_temp.xls" : "no_real_name_temp.xls";
      let url = "/static/" + urlDown;
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", urlDown);
      document.body.appendChild(link);
      link.click();
    },
    // 导入学员
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.fileKey = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    doExport() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.$confirm("是否将此表中学员绑定此考试?", "提示", {
          confirmButtonText: "确定",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            // if (this.fileKey) {
            ////////////
            const parmar = {
              examId: this.examId,
              excelUrl: this.fileKey,
              courseList: this.newvalues,
            };
            this.$post("/biz/exam/importExamUser", parmar, 5000)
              .then((res) => {
                if (res.status == "0") {
                  this.totalNum = res.data.totalNum;
                  this.errorNum = res.data.errorNum;
                  this.correctNum = res.data.correctNum;
                  this.importType = res.data.importType;
                  this.batchId = res.data.batchId;
                  this.$message({
                    type: "success",
                    message: "导入成功",
                  });
                }
              })
              .catch(() => {
                return;
              });
          })
          .catch(() => {
            return;
          });
      }
    },
    //导出错误数据
    doExportError() {
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post(
          "/biz/projectUser/exportProjectUser",
          { batchId: this.batchId, importType: this.importType },
          5000
        ).then((res) => {
          if (res.status == 0) {
            window.open(res.data);
          }
        });
      }
    },
    doCloseload() {
      this.centerDialogVisible = false;
      this.newvalues = [];
      this.fileKey = "";
      this.fileName = "";
      this.getData();
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {},
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.specialTestList {
  // 导入学员
  .exportdialog {
    .el-dialog__title {
      font-size: 16px;
    }
    .export-box {
      width: 100%;
      margin-bottom: 20px;
      h1 {
        padding: 0 5px 10px;
        display: flex;
        align-items: flex-end;
        font-size: 40px;
        border-bottom: 1px dashed #ccc;
        span {
          margin-left: 20px;
          font-size: 14px;
        }
      }
      > div {
        padding-left: 30px;
      }
      .el-button.is-disabled,
      .el-button.is-disabled:focus,
      .el-button.is-disabled:hover {
        color: #fff;
        background: #c3c3c3;
        border: none;
      }
    }
  }
}
</style>
<style lang="less" scope>
.specialTestList {
  .pic_content {
    margin-bottom: 20px;
    // background-color: #f2f7fd;
    padding: 20px;
    // border-radius: 10px;
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    h2 {
      text-align: center;
      margin-bottom: 18px;
    }
    div {
      p {
        margin-bottom: 6px;
      }
      p.title {
        font-size: 16px;
        font-weight: 600;
      }
      .pic_box {
        width: 130px;
        height: 130px;
        margin-bottom: 15px;
        // border: 1px solid #000;
      }
    }
  }
  // 导入学员
  .exportdialog {
    .upload-workers {
      height: 30px;
      .el-upload {
        height: 30px !important;
        width: 97px;
        border: none !important;
        .el-button {
          padding: 5px 0;
        }
      }
    }
  }
}
</style>

