<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <!-- 面包屑 - 当前位置 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人力法宝</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">订单列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <!-- 检索区域 -->
        <div class="operationControl flexdc" style="align-items:flex-start">
          <el-form
              :inline="true"
              ref="yzHIS_form"
              :model="searchForm"
              class="demo-form-inline yzHIS_form"
          >
            <el-form-item label="订单编号">
              <el-input v-model="searchForm.orderSn" clearable placeholder="请输入订单编号" size="small" />
            </el-form-item>
            <el-form-item label="订单状态">
              <el-select size="small" v-model="searchForm.orderStatus" clearable placeholder="请选择交易状态">
                <el-option v-for="item in orderStatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="订单日期">
              <el-date-picker
                  v-model="searchForm.orderDate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd" >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="支付日期">
              <el-date-picker
                  v-model="searchForm.payDate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd" >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="支付方式">
              <el-select size="small" v-model="searchForm.paymentType" clearable placeholder="请选择支付方式">
                <el-option v-for="item in paymentTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否自购">
              <el-select clearable size="small" v-model="searchForm.selfBuying" placeholder="请选择">
                <el-option v-for="item in selfBuyingList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="getData()" >查询</el-button>
            </el-form-item>
          </el-form>

        </div>
        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                :height="tableHeight"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="50px"
                  fixed="left"
                  :index="indexMethod"
              />
              <el-table-column
                  label="订单编号"
                  align="left"
                  prop="orderSn"
                  show-overflow-tooltip
                  min-width="110px"
              />
              <el-table-column
                  label="购买人"
                  align="left"
                  prop="buyerName"
                  show-overflow-tooltip
                  min-width="100px"
              />
<!--              <el-table-column-->
<!--                  label="受益人"-->
<!--                  align="left"-->
<!--                  prop="beneficiary"-->
<!--                  show-overflow-tooltip-->
<!--                  min-width="100px"-->
<!--              />-->
              <el-table-column
                  label="订单时间"
                  align="center"
                  prop="createTime"
                  show-overflow-tooltip
                  min-width="120px"
              />
              <el-table-column
                  label="订单金额"
                  align="right"
                  prop="orderAmount"
                  min-width="80px"
              />
              <el-table-column
                  label="支付方式"
                  align="center"
                  min-width="80px"
              >
                <template slot-scope="{row}">
                  {{ $setDictionary("DT_PAYMENT_TYPE", row.paymentType) }}
                </template>
              </el-table-column>
              <el-table-column
                  label="订单支付编号"
                  align="left"
                  prop="thirdPartPaymentNumber"
                  show-overflow-tooltip
                  min-width="120px"
              />
              <el-table-column
                  label="支付时间"
                  align="center"
                  prop="paymentTime"
                  show-overflow-tooltip
                  min-width="120px"
              />
              <el-table-column
                  label="支付金额"
                  align="right"
                  prop="paymentAmount"
                  show-overflow-tooltip
                  min-width="80px"
              />
              <el-table-column
                  label="交易状态"
                  align="center"
                  min-width="80px"
              >
                <template slot-scope="{row}">
                  {{ $setDictionary("DT_ORDER_STATUS", row.orderStatus) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="120px" fixed="right">
                <template slot-scope="{ row }" class="flexcc">
                  <el-button size="small" type="text" @click="openDialog(row)" >详情</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <!-- 分页 -->
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 订单详情弹框 -->
    <el-dialog title="订单详情" width="1016px" :visible.sync="showDialog" :before-close="closeDialog">
      <div class="container-css">
        <div class="order_title_css">
          <div class="left_css"><span class="title_css">订单</span> | <span class="context_css">{{dialogForm.orderSn}}</span></div>
          <div class="right_css">{{ $setDictionary("DT_ORDER_STATUS", dialogForm.orderStatus) }}</div>
        </div>
        <div class="order_product_css">
          <div class="product_css" v-for="item in dialogForm.productList" :key="item.id">
            <div class="left_css" >[购买{{ $setDictionary("DT_PRODUCT_TYPE", item.productType)}}] - {{item.productName}} </div>
            <div class="right_css" >X{{item.productCnt}}</div>
          </div>
        </div>
        <div class="order_amount_css" >
          <div class="amount_css">
            <div class="left_css">应付总额</div>
            <div class="right_css1"><span class="inner_css1" >￥</span>{{dialogForm.orderAmount}}</div>
          </div>
          <div class="amount_css">
            <div class="left_css">优惠金额</div>
            <div class="right_css1"><span class="inner_css1" >-￥</span>{{dialogForm.discountAmount ? dialogForm.discountAmount : 0}}</div>
          </div>
          <div class="amount_css">
            <div class="left_css">实付金额</div>
            <div class="right_css2"><span class="inner_css2" >￥{{dialogForm.paymentAmount}}</span></div>
          </div>
        </div>
        <div class="order_refund_css" v-if="showRefund" >
          <div class="row_css">
            <div class="left_css">已退金额</div>
            <div class="right_refund_css">￥{{dialogForm.refundAmount ? dialogForm.refundAmount : 0}}</div>
          </div>
          <div class="row_css">
            <div class="">退款时间</div>
            <div class="">{{ dialogForm.refundCompleteTime ? dialogForm.refundCompleteTime : '--' }}</div>
          </div>
          <div class="row_last_css" v-if="showRefundBtn">
            <div class="left_css">退款申请</div>
            <div class="right_css">
              <template>
                <el-popover
                    placement="left"
                    title="退款审批"
                    width="400"
                    v-model="showRefundDialog"
                    trigger="click">
                  <div>
                    <span style="color: #EF4C4C; margin-left: 80px">注意：须全额退款商品才会退货</span>
                    <el-form ref="refundForm" :model="refundForm" :rules="refundRule" label-width="80px">
                      <el-form-item label="退款金额" prop="refundAmount">
                        <el-input-number size="mini" controls-position="right" v-model="refundForm.refundAmount"
                                         :precision="2" :step="0.01" :min="0.01" :max="refundForm.maxAmount" ></el-input-number>
                      </el-form-item>
                      <el-form-item label="退款原因" prop="refundReason">
                        <el-input type="textarea" v-model="refundForm.refundReason" rows="4" size="small" maxlength="60" />
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" size="mini" @click="affirmRefund" >确认</el-button>
                        <el-button size="mini" @click="closeRefund">取消</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                  <el-button slot="reference" size="mini" icon="el-icon-check" class="refund_button_css">审批</el-button>
                </el-popover>
              </template>
            </div>
          </div>
          <div class="row_last_css" v-else >
            <div class="">退款详单</div>
            <div class="">
              <el-button size="mini" icon="el-icon-right" class="refund_details_css" @click="toRefundOrder(dialogForm.orderSn)" >查看</el-button>
            </div>
          </div>
        </div>
        <div class="order_line_css" ></div>
        <div class="order_payment_css">
          <div class="payment_css">
            <div class="left_css" >订单编号</div>
            <div class="right_css">{{dialogForm.orderSn}}</div>
          </div>
          <div class="payment_css">
            <div class="left_css" >下单时间</div>
            <div class="right_css">{{dialogForm.createTime}}</div>
          </div>
          <div class="payment_css">
            <div class="left_css" >支付单号</div>
            <div class="right_css">{{dialogForm.thirdPartPaymentNumber ? dialogForm.thirdPartPaymentNumber : '--'}}</div>
          </div>
          <div class="payment_css">
            <div class="left_css" >支付时间</div>
            <div class="right_css">{{dialogForm.paymentTime ? dialogForm.paymentTime : '--'}}</div>
          </div>
          <div class="payment_css">
            <div class="left_css" >支付方式</div>
            <div class="right_css">{{ $setDictionary("DT_PAYMENT_TYPE", dialogForm.paymentType)}}</div>
          </div>
        </div>
        <div class="order_client_title_css">
          购买人
        </div>
        <div class="order_client_content_css" >
          <div class="client_content_css" >
            <div class="left_css" >姓名</div>
            <div class="right_css" >{{dialogForm.buyerName ? dialogForm.buyerName : '--'}}</div>
          </div>
          <div class="client_content_css">
            <div class="left_css" >手机号</div>
            <div class="right_css" >{{dialogForm.buyerMobile}}</div>
          </div>
          <div class="client_content_last_css"></div>
        </div>
        <div class="order_client_title_css"
             v-if="showGrant" >
          获赠人
        </div>
        <div class="order_client_content_css"
             v-if="showGrant" >
          <div class="client_content_css" >
            <div class="left_css" >姓名</div>
            <div class="right_css" >{{dialogForm.customerName ? dialogForm.customerName : '--'}}</div>
          </div>
          <div class="client_content_css">
            <div class="left_css" >手机号</div>
            <div class="right_css" >{{dialogForm.customerAccount}}</div>
          </div>
          <div class="client_content_last_css"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageNum from "@/components/PageNum.vue"; // 分页器
import List from "@/mixins/List"; // 混入列表处理
import Empty from "@/components/Empty.vue";
export default {
  name: "manpower_order_orderList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      /* 搜索条件 */
      searchForm: {
        orderSn: '',         //订单编号
        orderStatus: '',     //订单状态(交易状态)
        paymentType: '',     //支付方式
        orderDate: '',       //订单日期
        orderDateStart: '',  //订单日期[开始]
        orderDateEnd: '',    //订单日期[结束]
        payDate: '',         //支付日期
        payDateStart: '',    //支付日期[开始]
        payDateEnd: '',      //支付日期[结束]
        selfBuying: '',      //自购状态
      },
      selfBuyingList: [
        {
          "value":true,
          "label":"是",
        },
        {
          "value":false,
          "label":"否",
        }
      ],                     //是否本人购买
      orderStatusList: [],   //订单交易状态列表
      paymentTypeList: [],   //支付方式列表
      showDialog: false,     //是否打开弹框
      showGrant: false,      //是否显示赠予信息
      dialogForm: {
      },
      showRefund: false,        //显示退款信息
      showRefundBtn: false,     //退款申请按钮可用

      showRefundDialog: false,  //显示退款框
      refundForm: {
        orderId: "",            //订单id
        refundAmount: "",       //退款金额
        refundReason: "",       //退款原因
        maxAmount: 0,           //最大退款金额
      },
      refundRule: {             //输入规则
        refundAmount: [
          { required: true, message: "请输入退款金额", trigger: 'blur' }
        ],
        refundReason: [
          { required: true, message: "请输入退款原因", trigger: 'blur' }
        ],
      },
    };
  },
  created() {
   this.getDictionary();
  },
  mounted() {
   this.getData();
  },
  methods: {
    getData(pageNum = 1) {
      // 获取列表数据
      const { orderSn, orderStatus, paymentType, selfBuying } = this.searchForm;
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if ('' !== orderSn) {
        params.orderSn = orderSn;
      }
      if ('' !== orderStatus) {
        params.orderStatus = orderStatus;
      }
      if (this.searchForm.orderDate && this.searchForm.orderDate.length > 0){
        params.orderDateStart = this.searchForm.orderDate[0];
        params.orderDateEnd = this.searchForm.orderDate[1];
      }
      if (this.searchForm.payDate && this.searchForm.payDate.length > 0){
        params.payDateStart = this.searchForm.payDate[0];
        params.payDateEnd = this.searchForm.payDate[1];
      }
      if ('' !== paymentType) {
        params.paymentType = paymentType;
      }
      if ('' !== selfBuying) {
        params.selfBuying = selfBuying;
      }
      this.doFetch({
        url: "/biz/order/mgt/v1/page",
        params,
        pageNum,
      }, true, 9);
    },
    // 获取字典
    getDictionary() {
      // 交易状态（订单状态）
      let arr1 = this.$setDictionary("DT_ORDER_STATUS", "list");
      for (const key in arr1) {
        this.orderStatusList.push({
          value: key,
          label: arr1[key],
        });
      }
      // 支付方式（订单状态）
      let arr2 = this.$setDictionary("DT_PAYMENT_TYPE", "list");
      for (const key in arr2) {
        this.paymentTypeList.push({
          value: key,
          label: arr2[key],
        });
      }
    },
    //打开窗口
    openDialog(row) {
      this.dialogForm = {};
      this.showRefund = false;
      this.showRefundBtn = false;
      this.showGrant = false;
      this.$post("/biz/order/mgt/v1/info", { "orderId": row.orderId,},
          3000, true, 9)
          .then((res) => {
            this.dialogForm = {... res.data};
            this.refundForm.orderId = this.dialogForm.orderId;
            this.refundForm.maxAmount = this.dialogForm.allowRefundAmount;
            //客户信息
            this.showGrant = !(this.dialogForm.buyerType === this.dialogForm.customerType &&
                this.dialogForm.buyerId === this.dialogForm.customerId);
            //已支付和部分退款时显示退款按钮
            if ('30' === this.dialogForm.orderStatus || '45' === this.dialogForm.orderStatus) {
              this.showRefundBtn = true;
            }
            //支付完成即可看到退款按钮
            if (this.dialogForm.paymentAmount > 0 &&
                '10' !== this.dialogForm.orderStatus &&
                '20' !== this.dialogForm.orderStatus) {
              this.showRefund = true;
            }
            this.showDialog = true;
          }).catch(() => { });
    },
    //关闭弹框
    closeDialog() {
      this.dialogForm = {};
      this.refundForm.orderId = "";
      this.refundForm.maxAmount = 0;
      this.showDialog = false;
      this.getData();
    },
    //确认退款
    affirmRefund() {
      this.$refs.refundForm.validate(valid => {
        if (valid) {
          this.$post("/biz/order/mgt/v1/refund",
              {
                "orderId": this.refundForm.orderId,
                "refundAmount": this.refundForm.refundAmount,
                "refundReason": this.refundForm.refundReason,
              },
              3000, true, 9)
              .then((res) => {
                if (res.data.success) {
                    this.closeRefund();
                    this.closeDialog();
                } else {
                  console.log('退款申请发生异常:', res.data);
                  this.$message.error("退款异常，请联系管理员")
                }
              })
              .catch(() => { });
        }
      });
    },
    //关闭退款申请
    closeRefund() {
      this.refundForm = {
        orderId: "",
        refundAmount: "",
        refundReason: "",
        maxAmount: 0,
      };
      this.showRefundDialog = false;
    },
    //前往退单列表
    toRefundOrder(orderSn) {
      this.$router.push({
        path: "/web/manpower/order/refundList",
        query: { orderSn: orderSn },
      });
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-dialog__header {
  background-color: #FFFFFF;
}
::v-deep .el-dialog__header .el-dialog__title {
  margin-top: 10px;
  color: #333333;
}
::v-deep .el-dialog__header .el-dialog__close {
  color: #333333;
}
::v-deep .el-dialog__body {
  padding: 10px 24px 24px 24px;
}
.container-css {
  width: 100%;
  display:inline-flex;
  flex-direction: column;
  justify-content: flex-start;

  .order_title_css {
    padding: 12px;
    background: #F7F8FA;
    display: inline-flex;
    justify-content: space-between;
    .left_css {
      .title_css {
        padding-right: 8px;
        font-weight: 600;
        color: #333333;
      }
      .context_css {
        padding-left: 8px;
        color: #666666;
      }
      .right_css {
        font-weight: 500;
        color: #333333;
        line-height: 14px;
      }
    }
  }
  .order_product_css {
    margin-top: 16px;
    padding: 0 12px 12px 12px;
    background: #F7F8FA;
    .product_css {
      padding-top: 12px;
      display: flex;
      justify-content: space-between;
      .left_css {
        color: #333333;
        line-height: 14px;
      }
      .right_css {
        color: #333333;
        line-height: 14px;
      }
    }
  }
  .order_amount_css {
    margin-top: 16px;
    padding: 16px 12px 0 12px;
    border-top: 2px dashed #DDDDDD;
    .amount_css {
      display: flex;
      justify-content: space-between;
      .left_css {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .right_css1 {
        padding-bottom: 12px;
        font-weight: 600;
        .inner_css1 {
          color: #666666;
        }
        .inner_css2 {
          font-weight: 600;
          color: #EF4C4C;
        }
      }
      .right_css2 {
        font-weight: 600;
        .inner_css1 {
          color: #666666;
        }
        .inner_css2 {
          font-weight: 600;
          color: #EF4C4C;
        }
      }
    }
  }
  .order_refund_css {
    margin-top: 15px;
    padding: 12px 12px 6px 12px;
    background: rgba(255,146,46,0.1);
    .row_css {
      padding-bottom: 8px;
      display: flex;
      justify-content: space-between;
      .right_refund_css {
        font-weight: 550;
        color: #EF4C4C;
      }
    }
    .row_last_css {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 8px;
      .refund_button_css {
        color: #FFFFFF;
        background: #EF4C4C;
        border-radius: 4px 4px 4px 4px;
      }
      .refund_details_css {
        color: #FFFFFF;
        background: #24B69B;
        border-radius: 4px 4px 4px 4px;
      }
    }

  }
  .order_line_css {
    padding: 8px 12px;
    border-bottom: 2px dashed #DDDDDD;
  }
  .order_payment_css {
    padding: 16px 12px 0 12px;
    .payment_css {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
      .left_css {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .right_css {
        color: #666666;
        font-weight: 400;
      }
    }
  }
  .order_client_title_css {
    margin: 4px 0;
    padding: 12px;
    background: #F7F8FA;
    display: inline-flex;
    justify-content: space-between;
    color: #333333;
    font-weight: 600;
  }
  .order_client_content_css {
    .client_content_css {
      padding: 12px 12px 0 12px;
      display: flex;
      justify-content: space-between;
      .left_css {
        color: #666666;
      }
      .right_css {
        color: #666666;
      }
    }
    .client_content_last_css {
      padding: 6px 12px;
    }
  }
}
</style>