<template>
  <div>
    <div class="operationControl">
      <div class="searchbox">
        <!-- <div title="课程要求总学时" class="searchboxItem">
          <span class="itemLabel">课程要求总学时:</span>
          <span>{{50}}</span>
        </div>-->
      </div>
      <div class="btnBox">
        <el-button type="primary" class="bgc-bv" round @click="handleAdd()" :disabled="auditState == '30' ||
          auditState == '20' ||
          auditState == '25' ||
          disTrue
          ? true
          : false
          ">新增课程</el-button>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
          style="width: 100%" :header-cell-style="tableHeader" stripe @cell-mouse-enter="M_Enter"
          @cell-mouse-leave="m_leave">
          <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
          <el-table-column label="课程名称" align="center" show-overflow-tooltip prop="courseName" width="200" />
          <el-table-column label="要求完成总学时" align="center" show-overflow-tooltip prop="lessonNumDemand" />
          <el-table-column label="视频累计时长" align="center" show-overflow-tooltip prop="kpointDurationStr" />
          <el-table-column label="上课学员数" align="center" show-overflow-tooltip prop="studyUserCount">
            <template slot-scope="scope">
              {{ scope.row.studyUserCount || 0 }}
            </template>
          </el-table-column>
          <el-table-column label="已完成学员数" align="center" show-overflow-tooltip prop="completeUserCount">
            <template slot-scope="scope">
              {{ scope.row.completeUserCount || 0 }}
            </template>
          </el-table-column>
          <el-table-column label="培训进度" align="center" prop="progress">
            <template slot-scope="scope">
              <el-progress :percentage="parseInt(scope.row.progress * 100)"></el-progress>
            </template>
          </el-table-column>
          <el-table-column label="价格" align="center" prop="progress">
            <template slot-scope="scope">
              <div v-if="!scope.row.orderShow">
                <span> {{ scope.row.price || 0 }}元</span>
                <i class="el-icon-edit" v-show="scope.row.showInput" @click="changeOrder('normalPrice', scope.row)"></i>
              </div>
              <el-input v-else v-model="scope.row.price" maxlength="1000" size="small" @blur="() => {
                editRowSort('normalPrice', scope.row);
              }
                ">
                <template slot="append">元</template></el-input>
            </template>
          </el-table-column>
          <el-table-column label="android补考价格" align="center" prop="androidPrice;">
            <template slot-scope="scope">
              <div v-if="!scope.row.androidShow">
                <span> {{ scope.row.androidPrice || 0 }}元</span>
                <i class="el-icon-edit" v-show="scope.row.androidShowInput"
                  @click="changeOrder('android', scope.row)"></i>
              </div>
              <el-input v-else v-model="scope.row.androidPrice" @keyup.native="
                scope.row.androidPrice = zF.oninput2(
                  scope.row.androidPrice,
                  2
                )
                " maxlength="1000" size="small" @blur="() => {
                  editRowSort('android', scope.row);
                }
                  ">
                <template slot="append">元</template></el-input>
            </template>
          </el-table-column>
          <el-table-column label="ios补考价格" align="center" prop="progress">
            <template slot-scope="scope">
              <div v-if="!scope.row.iosShow">
                <span> {{ scope.row.iosPrice || 0 }}元</span>
                <i class="el-icon-edit" v-show="scope.row.iosShowInput" @click="changeOrder('ios', scope.row)"></i>
              </div>
              <el-input v-else v-model="scope.row.iosPrice" @keyup.native="
                scope.row.iosPrice = zF.oninput2(
                  scope.row.iosPrice,
                  2
                )" maxlength="1000" size="small" @blur="() => {
                  editRowSort('ios', scope.row);
                }
                  "><template slot="append">元</template></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="350px">
            <div slot-scope="scope" class="flexcc">
              <el-button type="text" size="mini" style="padding:0 5px" @click="
                see(scope.row.projectCourseId, scope.row.courseId, 'stus')
                ">查看</el-button>
              <el-button type="text" size="mini" style="padding:0 5px" :disabled="auditState == '30' || auditState == '20' || auditState == '25'
                " @click="handleDel(scope.row.projectCourseId)">删除</el-button>
              <el-button type="text" size="mini" style="padding:0 5px" :disabled="auditState == '30'"
                @click="Setexamination(scope.row)" v-if="!scope.row.paperId">设置结业考试</el-button>
              <el-button v-else type="text" size="mini" style="padding:0 5px"
                @click="Setexamination(scope.row)">查看结业考试</el-button>
              <el-button v-if="!scope.row.ruleId" type="text" size="mini" style="padding:0 5px"
                :disabled="auditState == '30'" @click="SetRandomTestPaper(scope.row, 'set')">设置随机试卷</el-button>
              <el-button v-else type="text" size="mini" style="padding:0 5px"
                @click="SetRandomTestPaper(scope.row, 'edit')">查看随机试卷</el-button>
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <el-dialog title="结业考试" :visible.sync="dialogExamination" width="600px" top="15%" :center="true"
      :before-close="doClose">
      <div style="display:flex; flex-direction: column;">
        <div>
          <span>考试名称：</span>
          <span>{{ paperName }}</span>
        </div>
        <div style="padding-top: 15px">
          <span>通过分数：</span>
          <span>{{ paperScore }}分</span>
        </div>
        <div style="padding-top: 15px">
          <span>考试时间：</span>
          <span v-if="paperStartTime">{{ paperStartTime | momentWu }}至{{
            paperEndTime | momentWu
            }}</span>
          <span v-else>未设置考试时间</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: space-between;">
        <div>
          <el-button @click="cancle">取消</el-button>
        </div>
        <div class="btnBox">
          <el-button class="bgc-bv" @click="Examinationedit"
            :disabled="auditState == '30' || auditState == '20'">修改</el-button>
          <el-button @click="paperdelete" :disabled="auditState == '30' || auditState == '20'">删除</el-button>
        </div>
      </span>
    </el-dialog>
    <Mytestpaper ref="Mytestpaper" @eventBus="tableBack" @getData="getData" />
    <el-dialog class="del-tishi" title="提示" :visible.sync="dialogVisible" width="20%" :before-close="cancelCountdown">
      <span>
        你确定要删除该课程吗？删除课程后，该课的上课学员将会从本班移除，你可以通过课程替换功能修改课程。
        <br />请仔细阅读以上提示，
        <span class="theCountdown">{{ countdown }}</span>秒后可操作！
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelCountdown">取 消</el-button>
        <el-button @click="cancelYes" class="bgc-bv" :disabled="deleteConfirmation">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 随机试卷 -->
    <el-dialog title="随机试卷" :visible.sync="dialogRandom" width="750" top="1%" :center="true">
      <div style="display:flex; flex-direction: column;">
        <el-form ref="ruleRandomForm" label-width="8rem" class="" :rules="ruleRandomRules" :model="ruleRandomForm"
          :disabled="disabledRandomTestPaper">
          <el-form-item label="试卷名称:" prop="paperName">
            <el-input size="small" v-model="ruleRandomForm.paperName" placeholder="请输入试卷名称"></el-input>
          </el-form-item>
          <el-form-item label="指定题库:" prop="questionBankId">
            <el-select size="small" v-model="ruleRandomForm.questionBankId" placeholder="请选择题库"
              @change="questionbankChange1" filterable remote :remote-method="getquestionBank">
              <el-option :label="item.questionBankName" :value="item.questionBankId"
                v-for="(item, index) in questionbankList" :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="paperObj">
            <div>
              <p>
                在
                <span style="
                          display: inline-block;
                          width: 50px;
                          text-align: center;
                        ">{{ choiceNum }}</span>道单选题中随机抽
                <el-input-number v-model="ruleRandomForm.paperObj.SinglechoiceNum" size="small" :precision="0" :min="0"
                  :max="choiceNumMax" :disabled="!ruleRandomForm.questionBankId"
                  style="width: 140px; margin-right: 10px"></el-input-number>道题目，每题
                <el-input-number v-model="ruleRandomForm.paperObj.SinglechoiceScore" size="small" :min="0"
                  :disabled="!ruleRandomForm.questionBankId || choiceNum == 0"
                  style="width: 140px; margin-right: 10px"></el-input-number>分
              </p>
              <p>
                在
                <span style="
                          display: inline-block;
                          width: 50px;
                          text-align: center;
                        ">
                  {{ multiselectNum }} </span>道多选题中随机抽
                <el-input-number v-model="ruleRandomForm.paperObj.MultiplechoiceNum" size="small" :min="0"
                  :precision="0" :max="multiselectNumMax" :disabled="!ruleRandomForm.questionBankId"
                  style="width: 140px; margin-right: 10px"></el-input-number>道题目，每题
                <el-input-number v-model="ruleRandomForm.paperObj.MultiplechoiceScore" size="small" :min="0" :disabled="!ruleRandomForm.questionBankId || multiselectNum == 0
                  " style="width: 140px; margin-right: 10px"></el-input-number>分
              </p>
              <p>
                在
                <span style="
                          display: inline-block;
                          width: 50px;
                          text-align: center;
                        ">
                  {{ judgeNum }} </span>道判断题中随机抽
                <el-input-number v-model="ruleRandomForm.paperObj.judgeNum" size="small" :min="0" :precision="0"
                  :max="judgeNumMax" :disabled="!ruleRandomForm.questionBankId"
                  style="width: 140px; margin-right: 10px"></el-input-number>道题目，每题
                <el-input-number v-model="ruleRandomForm.paperObj.judgeScore" size="small" :min="0"
                  :disabled="!ruleRandomForm.questionBankId || judgeNum == 0"
                  style="width: 140px; margin-right: 10px"></el-input-number>分
              </p>
              <p>
                在
                <span style="
                          display: inline-block;
                          width: 50px;
                          text-align: center;
                        ">
                  {{ completionNum }} </span>道填空题中随机抽
                <el-input-number v-model="ruleRandomForm.paperObj.fillNum" size="small" :min="0" :precision="0"
                  :max="completionNumMax" :disabled="!ruleRandomForm.questionBankId"
                  style="width: 140px; margin-right: 10px"></el-input-number>道题目，每题
                <el-input-number v-model="ruleRandomForm.paperObj.fillScore" size="small" :min="0" :disabled="!ruleRandomForm.questionBankId || completionNum == 0
                  " style="width: 140px; margin-right: 10px"></el-input-number>分
              </p>
            </div>
          </el-form-item>
          <el-form-item label="试卷总分:" prop="paperTotalScore" required>
            <div class="">
              <el-input size="small" v-model="ruleRandomForm.paperTotalScore" disabled
                style="width: 200px; margin-right: 10px"></el-input><span>分</span>
            </div>
          </el-form-item>
          <el-form-item label="及格分数:" prop="paperScore">
            <div class="">
              <el-input-number v-model="ruleRandomForm.paperScore" :min="0" :precision="0" size="small"
                style="width: 200px; margin-right: 10px"></el-input-number>
              <span>分</span>
            </div>
          </el-form-item>
          <!-- <el-form-item label="统一考试时间:" prop="paperTime">
            <div class="">
              <el-date-picker
                v-model="ruleRandomForm.paperTime"
                type="datetimerange"
                align="right"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                size="small"
              ></el-date-picker>
            </div>
          </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: space-between;">
        <div></div>
        <div class="btnBox">
          <el-button class="bgc-bv" @click="RandomTestPaperSure('ruleRandomForm')">确定</el-button>

          <el-button @click="cancleDialogRandom('ruleRandomForm')">取消</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import Mytestpaper from "@/views/classListCom/classDetailsFile/popup/Mytestpaper";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "myresourseList",
  components: {
    Empty,
    PageNum,
    Mytestpaper,
  },
  mixins: [List],
  data() {
    let checkPaperObj = (rule, value, callback) => {
      let reg = /^[0-9]\d*$/;
      let {
        SinglechoiceNum,
        SinglechoiceScore,
        MultiplechoiceNum,
        MultiplechoiceScore,
        judgeNum,
        judgeScore,
        fillNum,
        fillScore,
      } = value;
      if (SinglechoiceNum + MultiplechoiceNum + judgeNum + fillNum == 0) {
        callback(new Error("请至少选择一项题型题目数量为有效值"));
      } else {
        if (SinglechoiceNum > 0 && SinglechoiceScore == 0) {
          callback(new Error("题目数量大于0的题型分数应为有效值"));
        } else if (MultiplechoiceNum > 0 && MultiplechoiceScore == 0) {
          callback(new Error("题目数量大于0的题型分数应为有效值"));
        } else if (judgeNum > 0 && judgeScore == 0) {
          callback(new Error("题目数量大于0的题型分数应为有效值"));
        } else if (fillNum > 0 && fillScore == 0) {
          callback(new Error("题目数量大于0的题型分数应为有效值"));
        } else {
          callback();
        }
      }
    };
    let passSorceCheck = (rule, value, callback) => {
      let reg = /^[0-9]\d*$/;
      console.log(value);
      if (value && !reg.test(value)) {
        callback(new Error("及格分数应为大于或等于0的整数"));
      } else if (Number(value) > this.ruleRandomForm.paperTotalScore) {
        callback(new Error("及格分数不能大于总分数"));
      } else if (value == undefined) {
        callback(new Error("请输入及格分数"));
      } else {
        callback();
      }
    };
    return {
      delectId: "",
      userJson: {},
      queryStr: "",
      dialogVisible: false,
      dialogExamination: false,
      dialogMytestpaper: false,
      activeName: "first",
      ruleForm: {
        source: "",
        Auditstatus: "",
        Salesstatus: "",
        Trainingtype: "",
        Creattime: "",
      },
      projectId: "",
      paperTotalScore: "",
      paperName: "",
      paperScore: "",
      paperStartTime: "",
      paperEndTime: "",
      projectCourseId: "",
      paperId: "",
      projectSource: "",
      countdown: "10",
      deleteConfirmation: true,
      timer: null,
      trainTypeId: "",
      typeofWorkList: [],
      disTrue: false,
      /* 设置题库组卷数据内容 start */
      dialogRandom: false,
      ruleRandomForm: {
        paperName: "", //试卷名称
        paperScore: "", // 及格分数
        paperTotalScore: "", // 总分数
        questionBankId: "", // 指定题库
        paperTime: [], //统一考试时间
        // 随机抽题时各题型的参数
        paperObj: {
          SinglechoiceNum: "0",
          SinglechoiceScore: 0,
          MultiplechoiceNum: "0",
          MultiplechoiceScore: 0,
          judgeNum: "0",
          judgeScore: 0,
          fillNum: "0",
          fillScore: 0,
        },
      },
      ruleRandomRules: {
        paperName: [{ required: true, message: "请输入试卷名称" }],
        questionBankId: [{ required: true, message: "请选择题库" }],
        paperTotalScore: [
          { required: true, message: "请选择试卷或试题计算总分数" },
        ],
        paperScore: [{ required: true, validator: passSorceCheck }],
        paperObj: [
          {
            required: true,
            validator: checkPaperObj,
            trigger: ["blur", "change"],
          },
        ],
      },
      choiceNum: 0,
      judgeNum: 0,
      multiselectNum: 0,
      completionNum: 0,
      choiceNumMax: 100,
      judgeNumMax: 100,
      multiselectNumMax: 100,
      completionNumMax: 100,
      questionbankList: [],
      ruleId: "",
      disabledRandomTestPaper: false,
      /* 设置题库组卷数据内容 end */
      PaperIosPriceSelect: [],
    };
  },
  computed: {},
  watch: {
    // 监听抽题数量变化 把分数清零
    "ruleRandomForm.paperObj.SinglechoiceNum": {
      handler: function (val) {
        console.log(val);
        if (val == 0) {
          this.ruleRandomForm.paperObj.SinglechoiceScore = 0;
        }
        if (val == undefined) {
          this.ruleRandomForm.paperObj.SinglechoiceNum = "0";
        }
      },
    },
    "ruleRandomForm.paperObj.MultiplechoiceNum": {
      handler: function (val) {
        if (val == 0) {
          this.ruleRandomForm.paperObj.MultiplechoiceScore = 0;
        }
        if (val == undefined) {
          this.ruleRandomForm.paperObj.MultiplechoiceNum = "0";
        }
      },
    },
    "ruleRandomForm.paperObj.judgeNum": {
      handler: function (val) {
        if (val == 0) {
          this.ruleRandomForm.paperObj.judgeScore = 0;
        }
        if (val == undefined) {
          this.ruleRandomForm.paperObj.judgeNum = "0";
        }
      },
    },
    "ruleRandomForm.paperObj.fillNum": {
      handler: function (val) {
        if (val == 0) {
          this.ruleRandomForm.paperObj.fillScore = 0;
        }
        if (val == undefined) {
          this.ruleRandomForm.paperObj.fillNum = "0";
        }
      },
    },
    // 计算总分
    "ruleRandomForm.paperObj": {
      handler: function (val) {
        let {
          SinglechoiceNum,
          SinglechoiceScore,
          MultiplechoiceNum,
          MultiplechoiceScore,
          judgeNum,
          judgeScore,
          fillNum,
          fillScore,
        } = this.ruleRandomForm.paperObj;
        this.ruleRandomForm.paperTotalScore = (
          SinglechoiceNum * SinglechoiceScore +
          MultiplechoiceNum * MultiplechoiceScore +
          judgeNum * judgeScore +
          fillNum * fillScore
        ).toFixed(1);
        // this.ruleRandomForm.paperScore = this.ruleRandomForm.paperTotalScore * (60/100)
      },
      deep: true,
    },
  },
  methods: {
    init() {
      this.projectId = sessionStorage.getItem("projectId");
      this.auditState = sessionStorage.getItem("auditState");
      this.projectSource = sessionStorage.getItem("projectSource");
      this.trainTypeId = sessionStorage.getItem("trainTypeId");
      this.getTableHeight();
      this.getData(-1);
      this.disTrue = false;
      if (this.trainTypeId == "16" && this.projectSource == "20") {
        this.disTrue = true;
        this.getTreeTrain();
      }
    },
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId,
      };
      this.doFetch({
        url: "/biz/projectCourse/listPage",
        params,
        pageNum,
      });
    },
    // 鼠标进入事件
    M_Enter(row, column, cell, event) {
      if (column.label == "价格") {
        row.showInput = true;
      }
      if (column.label == "android补考价格") {
        row.androidShowInput = true;
      }
      if (column.label == "ios补考价格") {
        row.iosShowInput = true;
      }
    },
    // 鼠标离开事件
    m_leave(row, column, cell, event) {
      row.showInput = false;
      row.androidShowInput = false;
      row.iosShowInput = false;
    },
    // 点击笔
    changeOrder(stu, row) {
      if (stu == "normalPrice") {
        row.orderShow = !row.orderShow;
      } else if (stu == "android") {
        row.androidShow = !row.androidShow;
      } else if (stu == "ios") {
        row.iosShow = !row.iosShow;
      }
    },
    //输入框失去焦点 保存修改
    editRowSort(stu, row) {
      let params = {
        projectCourseId: row.projectCourseId,
      };
      let url = "";
      if (stu == "normalPrice") {
        if(row.price == '0'){
          this.$message({
            message:'补考价格不能为0元',
            type:'error'
          })
          return false
        } else {
          params.price = row.price
        }
        url = "/biz/projectCourse/updatePrice";
      } else if (stu == "android") {
        if(row.androidPrice == '0'){
          this.$message({
            message:'补考价格不能为0元',
            type:'error'
          })
          return false
        } else {
          params.price = row.androidPrice
        }
        url = "/biz/projectCourse/androidPrice";
      } else if (stu == "ios") {
        if(row.iosPrice == '0'){
          this.$message({
            message:'补考价格不能为0元',
            type:'error'
          })
          return false
        } else {
          params.price = row.iosPrice
        }
        url = "/biz/projectCourse/iosPrice";
      }
      this.$post(url, params).then((res) => {
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.getData(-1);
      });
    },
    handleListDataBack(data) {
      this.tableData = [];
      data.map((el) => {
        el.showInput = false;
        el.orderShow = false;
        el.androidShowInput = false;
        el.androidShow = false;
        el.iosShowInput = false;
        el.iosShow = false;
        el.iosPrice = el.iosPrice ? el.iosPrice.toString() : ''
        this.tableData.push(el);

      });
      sessionStorage.setItem("courseCount", this.tableData.length);
      // sessionStorage.setItem("refresh", 10);
    },
    see(projectCourseId, courseId, stus) {
      this.$router.push({
        path: "/web/seeClassMinsCtr",
        query: {
          projectCourseId,
          auditState: this.auditState,
          courseId,
          stus: stus,
        },
      });
    },
    // 创建课程、查看编辑课程
    handleAdd() {
      this.$router.push({
        path: "/web/addCourseBoxBj",
      });
    },
    // 删除
    handleDel(id) {
      this.countdown = 10;
      this.delectId = id;
      this.dialogVisible = true;
      this.deleteConfirmation = true;
      this.timer = setInterval(() => {
        --this.countdown;
        if (this.countdown == 0) {
          clearInterval(this.timer);
          this.deleteConfirmation = false;
        }
      }, 1000);
    },
    //确定删除
    cancelYes() {
      this.dialogVisible = false;
      this.$post("/biz/projectCourse/deleteProjectCourse", {
        projectCourseId: this.delectId,
      }).then((res) => {
        this.delectId = "";
        this.getData();
        this.disTrue = false;
        setTimeout(() => {
          sessionStorage.setItem("courseCount", this.tableData.length);
          console.log(this.tableData.length);
        }, 1000);
      });
      this.init();
      clearInterval(this.timer);
    },
    //点击取消
    cancelCountdown() {
      (this.countdown = "10"),
        (this.dialogVisible = false),
        clearInterval(this.timer);
    },
    tableBack() {
      this.cancle();
    },
    // 设置课后考试
    Setexamination(row) {
      if (this.auditState == "30" || this.auditState == "20") {
        if (row.paperId) {
          this.dialogExamination = true;
          this.paperScore = row.paperScore;
          this.paperName = row.paperName;
          this.paperStartTime = row.paperStartTime;
          this.paperEndTime = row.paperEndTime;
          this.projectCourseId = row.projectCourseId;
          this.paperId = row.paperId;
        } else {
          this.$alert("该课程未设置结业考试", "提示", {
            confirmButtonText: "确定",
            confirmButtonClass: "confirmButtonClass",
            callback: (action) => { },
          });
        }
      } else {
        if (row.paperId) {
          this.dialogExamination = true;
          this.paperScore = row.paperScore;
          this.paperName = row.paperName;
          this.paperStartTime = row.paperStartTime;
          this.paperEndTime = row.paperEndTime;
          this.projectCourseId = row.projectCourseId;
          this.paperTotalScore = row.paperTotalScore;
          this.paperId = row.paperId;
        } else {
          this.$refs.Mytestpaper.showPopUp(
            row.paperTotalScore,
            row.projectCourseId,
            row.paperScore,
            row.paperName,
            row.paperId,
            row.paperStartTime,
            row.paperEndTime
          );
        }
      }
    },
    paperdelete() {
      this.$confirm("是否删除结业考试?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "confirmButtonClass",
        type: "warning",
      })
        .then(() => {
          this.$post("/biz/projectCourse/deleteProjectCoursePaper", {
            projectCourseId: this.projectCourseId,
          })
            .then((ret) => {
              this.dialogExamination = false;
              this.$message({
                message: ret.message,
                type: "success",
              });
              this.getData(-1);
            })
            .catch((err) => {
              return;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    Examinationedit() {
      this.$refs.Mytestpaper.showPopUp(
        this.paperTotalScore,
        this.projectCourseId,
        this.paperScore,
        this.paperName,
        this.paperId,
        this.paperStartTime,
        this.paperEndTime
      );
    },
    // 上课学员
    StudentsInClass(projectCourseId) {
      sessionStorage.setItem("projectCourseId", projectCourseId);
      this.$router.push({
        path: "/web/StudentsInClass",
      });
    },
    //取消
    cancle() {
      this.dialogExamination = false;
    },

    canclepaper() {
      this.dialogExamination = false;
    },
    doClose() {
      this.dialogExamination = false;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40 + 15;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getTreeTrain() {
      this.$post("/biz/sou/cc/querySourceProjectCourseList", {
        projectId: sessionStorage.getItem("projectId"),
        isAll: true,
      }).then((ret) => {
        this.typeofWorkList = ret.data;
        if (this.typeofWorkList.length == this.tableData.length) {
          this.disTrue = true;
        }
      });
    },
    //设置随机考试
    SetRandomTestPaper(row, stu) {
      this.dialogRandom = true;
      this.ruleId = row.ruleId || "";
      if (row.ruleId) {
        this.getRuleTestPaperInfo(row.projectId);
      }
      if (
        stu == "edit" &&
        (this.auditState == "30" || this.auditState == "20")
      ) {
        this.disabledRandomTestPaper = true;
      }
    },
    getRuleTestPaperInfo(projectId) {
      this.$post("/run/project/paper/rule/callBack", { projectId }).then(
        (res) => {
          this.ruleRandomForm = {
            ...this.ruleRandomForm,
            paperName: res.data.paperName,
            questionBankId: res.data.questionBankId,
            paperObj: {
              SinglechoiceNum: res.data.singleChoiceNum, //单选题数量
              SinglechoiceScore: res.data.singleChoiceScore, //单选题分数
              MultiplechoiceNum: res.data.multipleChoiceNum, //多选题数量
              MultiplechoiceScore: res.data.multipleChoiceScore, //多选题分数
              judgeNum: res.data.judgeNum, //判断题数量
              judgeScore: res.data.judgeScore, //判断题分数
              fillNum: res.data.fillNum, //填空题数量
              fillScore: res.data.fillScore, //填空题分数
            },
            paperTotalScore: res.data.paperTotalScore, //总分
            paperScore: res.data.paperScore, //通过分数
          };
          this.questionbankChange(res.data.questionBankId); // 查题库中的题目数量
        }
      );
    },
    RandomTestPaperSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let parmar = {
            paperName: this.ruleRandomForm.paperName, //试卷名称
            questionBankId: this.ruleRandomForm.questionBankId, //题库Id
            singleChoiceNum: this.ruleRandomForm.paperObj.SinglechoiceNum, //单选题数量
            singleChoiceScore: this.ruleRandomForm.paperObj.SinglechoiceScore, //单选题分数
            multipleChoiceNum: this.ruleRandomForm.paperObj.MultiplechoiceNum, //多选题数量
            multipleChoiceScore: this.ruleRandomForm.paperObj
              .MultiplechoiceScore, //多选题分数
            judgeNum: this.ruleRandomForm.paperObj.judgeNum, //判断题数量
            judgeScore: this.ruleRandomForm.paperObj.judgeScore, //判断题分数
            fillNum: this.ruleRandomForm.paperObj.fillNum, //填空题数量
            fillScore: this.ruleRandomForm.paperObj.fillScore, //填空题分数
            paperTotalScore: this.ruleRandomForm.paperTotalScore, //总分
            paperScore: this.ruleRandomForm.paperScore, //通过分数
            paperStartTime: "",
            paperEndTime: "",
            projectId: this.projectId, //班级Id
          };
          if (this.ruleId) {
            parmar.ruleId = this.ruleId;
          }
          this.$post("/run/project/paper/rule/generative", parmar).then(
            (res) => {
              if (res.status == 0) {
                this.$message({
                  message: "设置随机考试成功",
                  type: "success",
                });
                this.cancleDialogRandom(formName);
                this.getData(-1);
              }
            }
          );
        } else {
          return false;
        }
      });
    },
    //取消随机考试
    cancleDialogRandom(formName) {
      this.dialogRandom = false;
      this.$refs[formName].resetFields();
      this.ruleRandomForm = {
        paperObj: {
          SinglechoiceNum: "0",
          SinglechoiceScore: 0,
          MultiplechoiceNum: "0",
          MultiplechoiceScore: 0,
          judgeNum: "0",
          judgeScore: 0,
          fillNum: "0",
          fillScore: 0,
        },
      };
    },
    // 指定题库
    getquestionBank(query) {
      if (query) {
        this.questionbankList = [];
        this.$post("/run/project/paper/rule/questionBank", {
          questionBankName: query,
        }).then((res) => {
          this.questionbankList = res.data;
        });
      }
    },
    questionbankChange(val) {
      this.questionbankList = [];
      this.$post("/run/project/paper/rule/questionBank", {
        questionBankId: val,
      }).then((res) => {
        // ;
        this.questionbankList = res.data;
        let item = this.questionbankList.find((el) => el.questionBankId == val);
        this.choiceNum = item.choiceNum || 0;
        this.judgeNum = item.judgeNum || 0;
        this.multiselectNum = item.multiselectNum || 0;
        this.completionNum = item.completionNum || 0;
        this.choiceNumMax = item.choiceNum || 0;
        this.judgeNumMax = item.judgeNum || 0;
        this.multiselectNumMax = item.multiselectNum || 0;
        this.completionNumMax = item.completionNum || 0;
      });
    },
    questionbankChange1(val) {
      this.questionbankList = [];
      this.$post("/run/project/paper/rule/questionBank", {
        questionBankId: val,
      }).then((res) => {
        // ;
        this.questionbankList = res.data;
        let item = this.questionbankList.find((el) => el.questionBankId == val);
        this.choiceNum = item.choiceNum || 0;
        this.judgeNum = item.judgeNum || 0;
        this.multiselectNum = item.multiselectNum || 0;
        this.completionNum = item.completionNum || 0;
        this.choiceNumMax = item.choiceNum || 0;
        this.judgeNumMax = item.judgeNum || 0;
        this.multiselectNumMax = item.multiselectNum || 0;
        this.completionNumMax = item.completionNum || 0;
        this.ruleRandomForm.paperScore = "";
        this.ruleRandomForm.paperObj = {
          SinglechoiceNum: "0",
          SinglechoiceScore: 0,
          MultiplechoiceNum: "0",
          MultiplechoiceScore: 0,
          judgeNum: "0",
          judgeScore: 0,
          fillNum: "0",
          fillScore: 0,
        };
      });
    },
  },
};
</script>
<style lang="less">
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;

    &:before {
      content: "\e6df" !important;
    }
  }

  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;

    .el-tabs__content {
      flex: 1;
    }
  }
}

.btnBox {

  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}

.del-tishi {
  .el-dialog__header {
    background: #ffffff;
    text-align: left;
  }

  .el-dialog__title {
    color: #333;
  }

  .el-dialog__body {
    .theCountdown {
      color: red;
      font-size: 16px;
    }
  }

  .el-dialog__headerbtn {
    .el-dialog__close {
      color: black;
    }
  }
}
</style>
