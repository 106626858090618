<template>
  <div>
    <div>
      <div class="operationControl personalSpecialOrder">
        <div class="searchbox" style="margin-bottom: 10px;">
          <el-popover placement="bottom" width="100">
            <div class="flexac">
              <el-button type="text" @click="downloadList(1)" :disabled="!projectId">xls</el-button>
              <el-button type="text" @click="downloadList(2)" :disabled="!projectId && !allowPrint">pdf</el-button>
            </div>
            <el-button class="bgc-bv" round slot="reference">下载学习情况汇总</el-button>
          </el-popover>
          <el-popover placement="bottom" width="100">
            <div class="flexac">
              <el-button type="text" @click="downloadList(9)" :disabled="!projectId">xls</el-button>
              <el-button type="text" @click="downloadList(10)" :disabled="!projectId && !allowPrint">pdf</el-button>
            </div>
            <el-button class="bgc-bv" round slot="reference">下载学习记录汇总</el-button>
          </el-popover>
          <el-popover placement="bottom" width="100">
            <div class="flexac">
              <el-button type="text" @click="downloadList(3)" :disabled="!projectId">xls</el-button>
              <el-button type="text" @click="downloadList(4)" :disabled="!projectId && !allowPrint">pdf</el-button>
            </div>
            <el-button class="bgc-bv" round slot="reference">下载学习记录汇总（按登录）</el-button>
          </el-popover>
          <el-popover placement="bottom" width="100">
            <div class="flexac">
              <el-button type="text" @click="downloadList(5)" :disabled="!projectId">xls</el-button>
              <el-button type="text" @click="downloadList(6)" :disabled="!projectId && !allowPrint">pdf</el-button>
            </div>
            <el-button class="bgc-bv" round slot="reference">下载学习记录汇总（按课节）</el-button>
          </el-popover>
          <el-popover placement="bottom" width="100">
            <div class="flexac">
              <el-button type="text" @click="downloadList(7)" :disabled="!projectId">xls</el-button>
              <el-button type="text" @click="downloadList(8)" :disabled="!projectId && !allowPrint">pdf</el-button>
            </div>
            <el-button class="bgc-bv" round slot="reference">下载学习汇总表（奉贤区）</el-button>
          </el-popover>
        </div>
        <div class="searchbox">
          <el-popover placement="bottom" width="100">
            <div class="flexac">
              <el-button type="text" @click="downloadList(12)" :disabled="!projectId">xls</el-button>
              <el-button type="text" @click="downloadList(13)" :disabled="!projectId && !allowPrint">pdf</el-button>
            </div>
            <el-button class="bgc-bv" round slot="reference">下载学习记录汇总（青浦区徐泾镇）</el-button>
          </el-popover>
          <el-popover placement="bottom" width="100">
            <div class="flexac">
              <el-button type="text" @click="downloadList(11)" :disabled="!projectId">xls</el-button>
            </div>
            <el-button class="bgc-bv" round slot="reference">下载班级数据统计表（黄浦区）</el-button>
          </el-popover>
          <el-popover placement="bottom" width="100">
            <div class="flexac">
              <el-button type="text" @click="downloadList(14)" :disabled="!projectId && !allowPrint">pdf</el-button>
            </div>
            <el-button class="bgc-bv" round slot="reference">下载授课记录表（普陀区）</el-button>
          </el-popover>
        </div>        
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
            style="width: 100%" stripe :header-cell-style="tableHeader">
            <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="80px" />
            <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" width="80px" />
            <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" width="150px" />
            <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" width="100px" />
            <el-table-column label="开始学习时间" align="left" show-overflow-tooltip prop="startTimeStr" width="150px" />
            <el-table-column label="完成学习时间" align="left" show-overflow-tooltip prop="endTimeStr" width="150px" />
            <el-table-column label="签到天数" align="left" show-overflow-tooltip prop="checkInDays" width="100px" />
            <el-table-column label="有效学习总时长" align="left" show-overflow-tooltip prop="totalStudyDuration"
              width="120px" />
            <el-table-column label="学习累计总时长" align="left" show-overflow-tooltip prop="totalOnlineDuration"
              width="120px" />
            <el-table-column label="考试时间" align="left" show-overflow-tooltip prop="examTime" width="200px" />
            <el-table-column label="考试用时" align="left" show-overflow-tooltip prop="examUseTime" width="150px" />
            <el-table-column label="考试成绩" align="left" show-overflow-tooltip prop="scores" width="100px" />
            <el-table-column label="是否合格" align="left" show-overflow-tooltip prop="isPassed" width="100px" />
            <el-table-column label="操作" align="center" fixed="right" width="100px">
              <template slot-scope="scope">
                <el-button type="text" style="padding: 0px 5px" size="mini"
                  @click="goLearningPath(scope.row)">学习轨迹</el-button>
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "ciq_type_3",
  components: {
    Empty,
    PageNum,
  },
  props: {
    // 班级
    projectId: {
      type: String,
      default: "",
    },
    // 学员
    userId: {
      type: String,
      default: "",
    },
    // tap选项卡
    tabs: {
      type: String,
      default: "",
    },
  },
  mixins: [List],
  doNotInit: true,
  data() {
    return {
      // 是否可以下载
      allowPrint: false,
    };
  },
  watch: {
    // 监听 - tap选项卡
    tabs: {
      handler: function (val) {
        if (val == "type_3" && this.projectId) {
          this.getData(-1);
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems"
    })
  },
  mounted() { },
  created() {
    this.getTableHeight();
  },
  methods: {
    // 查询 - 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId,
        userId: this.userId
      };
      this.doFetch(
        {
          url: "/biz/project/getUserStudyRecord",
          params,
          pageNum,
        },
      );
      this.get_allow_print();
    },
    // 获取 - 是否可以下载
    get_allow_print() {
      this.$post("/biz/project/getProjectInfo", { projectId: this.projectId }).then((res) => {
        this.allowPrint = res.data.allowPrint;
      });
    },
    // 导出 - 列表数据
    downloadList(type) {
      let cData = {
        projectId: this.projectId
      }
      // 导出 - 下载学习情况汇总(xls)
      if (type == 1) {
        cData.downloadFileType = '10';
        cData.identifier = "SHANGHAI_E60";
        cData.cached = false;
      }
      // 导出 - 下载学习情况汇总(pdf)
      if (type == 2) {
        cData.downloadFileType = '20';
        cData.identifier = "SHANGHAI_E60";
      }
      // 导出 - 下载学习记录汇总(按登录)(xls)
      if (type == 3) {
        cData.downloadFileType = '10';
        cData.identifier = "SHANGHAI_E110";
        cData.cached = false;
      }
      // 导出 - 下载学习记录汇总(按登录)(pdf)
      if (type == 4) {
        cData.downloadFileType = '20';
        cData.identifier = "SHANGHAI_E110";
      }
      // 导出 - 下载学习记录汇总(按课节)(xls)
      if (type == 5) {
        cData.downloadFileType = '10';
        cData.identifier = "SHANGHAI_E160";
        cData.cached = false;
      }
      // 导出 - 下载学习记录汇总(按课节)(pdf)
      if (type == 6) {
        cData.downloadFileType = '20';
        cData.identifier = "SHANGHAI_E160";
      }
      // 导出 - 下载学习记录汇总(xls)
      if (type == 9) {
        cData.downloadFileType = '10';
        cData.identifier = "SHANGHAI_E131";
        cData.cached = false;
      }
      // 导出 - 下载学习记录汇总(pdf)
      if (type == 10) {
        cData.downloadFileType = '20';
        cData.identifier = "SHANGHAI_E131";
      }
      // 导出 - 下载学习汇总表(奉贤区)(xls)
      if (type == 7) {
        cData.downloadFileType = '10';
        cData.identifier = "SHANGHAI_E140";
        cData.cached = false;
      }
      // 导出 - 下载学习汇总表(奉贤区)(pdf)
      if (type == 8) {
        cData.downloadFileType = '20';
        cData.identifier = "SHANGHAI_E140";
      }
      // 导出 - 下载班级数据统计表(黄浦区)(xls)
      if (type == 11) {
        cData.downloadFileType = '10';
        cData.identifier = "SHANGHAI_E170";
      }
      // 导出 - 学习记录汇总（按课节_青浦区徐泾镇）(pdf)
      if (type == 12) {
        cData.downloadFileType = '10';
        cData.identifier = "SHANGHAI_E180";
      }
      // 导出 - 学习记录汇总（按课节_青浦区徐泾镇）(xls)
      if (type == 13) {
        cData.downloadFileType = '20';
        cData.identifier = "SHANGHAI_E180";
      }
      // 导出 - 下载授课记录表(普陀区)(pdf)
      if (type == 14) {
        cData.downloadFileType = '20';
        cData.identifier = "SHANGHAI_E150";
      }
      this.$post("/sys/download/shanghai/api", cData).then((res) => {
        if (res.status == '0') {
          for (let item of res.data) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        } else {
          this.$message.error(re.message);
        }
      });
    },
    // 学习轨迹
    goLearningPath(row) {
      this.$router.push({
        path: "/set/ciq/type_3_Info",
        query: {
          projectId: this.projectId,
          userId: row.userId
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1 + 110;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less" scoped>
.personalSpecialOrder {
  border-bottom: 1px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}
</style>
