<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">工伤预防对接班级</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="学员搜索" class="searchboxItem ci-full">
              <span class="itemLabel">学员搜索:</span>
              <el-select v-model="userId" placeholder="请选择" no-data-text="没有数据" remote size="small" clearable
                :remote-method="superUserSelect" @visible-change="clearUserSearchModel">
                <template>
                  <div class="select-header">
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      ">学员姓名</span>
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      ">身份证号码</span>
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      ">学员电话</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="seaUserName" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="学员姓名" clearable style="width: 100px" />
                    <el-input v-model="seaUserIdcard" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="身份证号码" clearable style="margin-left: 50px; width: 140px" />
                    <el-input v-model="seaUserMobile" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="学员电话" clearable style="margin-left: 50px; width: 100px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in userList" :key="index" :label="item.userName" :value="item.userId">
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span style="width: 140px; margin-left: 50px; font-size: 13px">{{ item.idcard }}</span>
                  <span style="width: 100px; margin-left: 50px; font-size: 13px">{{ item.mobile }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="班级搜索" style="display: flex; align-items: center" class="searchboxItem ci-full">
              <span class="itemLabel">班级搜索:</span>
              <el-select v-model="projectId" placeholder="请选择" remote size="small" clearable
                :remote-method="superProjectSelect" @visible-change="clearProjectSearchModel">
                <template>
                  <div class="select-header">
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      ">班级编号</span>
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      ">班级名称</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="seaProjectCode" v-on:input="superProjectSelect" type="text" size="small"
                      placeholder="班级编号" clearable style="width: 150px" />
                    <el-input v-model="seaProjectName" v-on:input="superProjectSelect" type="text" size="small"
                      placeholder="请输入班级名称" clearable style="margin-left: 50px; width: 250px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in projectList" :key="index" :label="item.projectName"
                  :value="item.projectId">
                  <span style="width: 150px; font-size: 13px">{{
                    item.projectCode
                  }}</span>
                  <span style="width: 250px; margin-left: 50px; font-size: 13px">{{ item.projectName }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select size="small" v-model="compId" remote :remote-method="getCompanyList" filterable clearable
                placeholder="请至少输入两个字搜索" style="width: 100%">
                <el-option v-for="(item, index) in CompanyList" :key="index" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <div title="所属企业" class="searchboxItem ci-full">
              <span class="itemLabel">所属企业:</span>
              <el-select size="small" v-model="userCompId" remote :remote-method="getuserCompanyList" filterable
                clearable placeholder="请至少输入两个字搜索" style="width: 100%">
                <el-option v-for="(item, index) in userCompanyList" :key="index" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <el-button type="primary" size="small" class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
        </div>

        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" fixed :index="indexMethod" width="100px" />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" fixed />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" fixed width="160" />
              <el-table-column label="性别" align="center" show-overflow-tooltip width="60px" prop="sex">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.sex == "1"
                      ? "男"
                      : scope.row.sex == "2"
                        ? "女"
                        : ""
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="联系电话" width="100" align="left" show-overflow-tooltip prop="mobile" />
              <el-table-column label="学员类型" width="230" align="left" show-overflow-tooltip>
                <template slot-scope="scope">{{ $setDictionary("USERTYPE", scope.row.userType) }}</template>
              </el-table-column>
              <el-table-column label="机构名称" width="200" align="left" show-overflow-tooltip prop="compName" />
              <el-table-column label="认证状态" align="left" show-overflow-tooltip>
                <template slot-scope="scope">{{ $setDictionary("AUTHENTICATION", scope.row.authentication) }}</template>
              </el-table-column>
              <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" width="200" />
              <el-table-column label="课程名称" align="left" show-overflow-tooltip prop="courseName" width="200" />
              <el-table-column label="操作" fixed="right" align="center" width="150px">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding: 0px 5px" size="mini" :disabled="!scope.row.isPush"
                    @click="gsyfDelUser(scope.row)">删除</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini" :disabled="scope.row.isPush"
                    @click="gsyfPushUser(scope.row)">推送</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini" :disabled="!scope.row.isPush"
                    @click="updateUnit(scope.row)">更新单位</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { Message } from "element-ui";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "gsyfProject_projectList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      userId: "", //学员Id
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
      projectId: "", //班级Id
      seaProjectCode: "",
      seaProjectName: "",
      projectList: [{}],
      compId: "", //机构Id
      userCompId: "", //所属企业Id
      //机构名称下拉数据
      CompanyList: [],
      //所属企业下拉数据
      userCompanyList: [],
      userWorkUnit: "", //工作单位

    };
  },
  //页面初始化时不自动查询列表数据
  doNotInit: true,
  created() {
    this.getTableHeight();
    this.superUserSelect();
    this.superProjectSelect();
  },
  computed: {
  },
  watch: {
    projectId: function () {
      this.superUserSelect();
    },
    userId: function () {
      this.superProjectSelect();
    },
  },
  methods: {
    superUserSelect(e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
          projectId: this.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    superProjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.seaProjectName,
          projectCode: this.seaProjectCode,
          userId: this.userId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.projectList = [{}];
        } else {
          this.projectList = res.data.list;
        }
      });
    },
    getSearchParams() {
      let params = {
        projectId: this.projectId || "",
        userId: this.userId || "",
        compId: this.compId || "",
        userCompId: this.userCompId || "",
      };
      return params;
    },
    clearProjectSearchModel(e) {
      if (e) {
        this.seaProjectName = "";
        this.seaProjectCode = "";
        this.superProjectSelect();
      }
    },
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    getData(pageNum = 1) {
      if (this.projectId == "" && this.userId == "" && this.compId == "") {
        Message.error({
          message: "班级、学员、机构必须选择一项",
          duration: "2000",
        });
        return;
      }
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      let par = this.getSearchParams();
      params = {
        ...params,
        ...par,
      };
      this.doFetch({
        url: "/biz/gsyf/projectUserList",
        params,
        pageNum,
      });
    },
    //删除学员
    gsyfDelUser(row) {
      const { userId, projectId } = row;
      this.$confirm('请确认是否在 [ 工伤预防项目管理平台 ] 移除该学员？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        confirmButtonClass: "bgc-bv",
      }).then(() => {
        // 数据回显
        this.$post("/biz/gsyf/removeUser", {
          userId,
          projectId
        }).then(res => {
          this.getData();
          this.$message.success("删除成功");
        });
      });
    },
    //推送学员
    gsyfPushUser(row) {
      const { userId, projectId } = row;
      this.$confirm('请确认是否将学员信息推送至 [ 工伤预防项目管理平台 ] ？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        confirmButtonClass: "bgc-bv",
      }).then(() => {
        // 数据回显
        this.$post("/biz/gsyf/pushUser", {
          userId,
          projectId
        }).then(res => {
          this.getData();
          this.$message.success("推送成功");
        });
      });
    },
    // 更新单位
    updateUnit(row){
      const { userId, projectId } = row;
      this.$post("/biz/gsyf/updateUserCompany", {
          userId,
          projectId
        }).then(res => {
          this.getData(this.pageNum);
          this.$message.success("更新成功");
        });
    },
    // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    /* 所属企业 */
    getuserCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.userCompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.userCompanyList = [];
      }
    },
    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyList = [res.data];
      });
    },
    getTime(val) {
      if (!val) {
        return "";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return h + "时" + m + "分" + s + "秒" || "";
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 9) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    handleDetail(userId, projectCourseId) {
      this.$router.push({
        path: "/web/operate/learningDetails",
        query: { userId, projectCourseId },
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

.operationStudent {
  .itemLabel {
    min-width: 5rem;
  }

  .ci-full {
    .el-input--suffix .el-input__inner {
      padding-right: 3px;
    }

    .el-input {
      input {
        width: 100% !important;
      }
    }

    .smallselect {
      input {
        width: 100% !important;
      }
    }
  }
}

.el-select__tags-text {
  min-width: 2rem !important;
}

.el-selectBox {
  .el-select {

    // min-width: 15rem;
    .el-tag {
      min-width: 10px !important;
    }
  }
}
</style>
