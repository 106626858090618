<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <!-- 面包屑 - 当前位置 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">人力法宝</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">退单列表</a>
        </span>
      </div>
      <!-- 页面主体 -->
      <div class="framePage-body">
        <!-- 检索区域 -->
        <div class="operationControl flexdc" style="align-items:flex-start">
          <el-form
              :inline="true"
              ref="yzHIS_form"
              :model="searchForm"
              class="demo-form-inline yzHIS_form"
          >
            <el-form-item label="退款编号">
              <el-input v-model="searchForm.refundSn" clearable placeholder="请输入退款单号" size="small" />
            </el-form-item>
            <el-form-item label="订单编号">
              <el-input v-model="searchForm.orderSn" clearable placeholder="请输入订单编号" size="small" />
            </el-form-item>
            <el-form-item label="退款日期">
              <el-date-picker
                  v-model="searchForm.refundDate"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd" >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="退款状态">
              <el-select size="small" v-model="searchForm.refundState" clearable placeholder="请选择退款状态">
                <el-option v-for="item in refundStateList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="getData()" >查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="50px"
                  fixed="left"
                  :index="indexMethod"
              />
              <el-table-column
                  label="退款单号"
                  align="left"
                  prop="refundSn"
                  show-overflow-tooltip
                  min-width="110px"
              />
              <el-table-column
                  label="订单编号"
                  align="left"
                  prop="orderSn"
                  show-overflow-tooltip
                  min-width="110px"
              />
              <el-table-column
                  label="订单金额"
                  align="right"
                  prop="orderAmount"
                  min-width="80px"
              />
              <el-table-column
                  label="退款金额"
                  align="right"
                  prop="refundAmount"
                  min-width="80px"
              />
              <el-table-column
                  label="申请时间"
                  align="center"
                  prop="createTime"
                  show-overflow-tooltip
                  min-width="120px"
              />
              <el-table-column
                  label="退款时间"
                  align="center"
                  prop="completeTime"
                  show-overflow-tooltip
                  min-width="120px"
              />
              <el-table-column
                  label="退款状态"
                  align="center"
                  min-width="80px"
              >
                <template slot-scope="{row}">
                  {{ $setDictionary("DT_REFUND_STATE", row.refundState) }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="120px" fixed="right">
                <template slot-scope="{ row }" class="flexcc">
                  <el-button size="small" type="text" @click="openDialog(row)" >详情</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <!-- 分页 -->
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 弹框 -->
    <!-- 退款详情弹框 -->
    <el-dialog title="退款详情" width="800px" :visible.sync="showDialog" :before-close="closeDialog">
      <div class="container-css">
        <div class="order_title_css">
          <div class="left_css"><span class="title_css">退款单</span> | <span class="context_css">{{dialogForm.refundSn}}</span></div>
          <div class="right_css" @click="refresh">
            {{ $setDictionary("DT_REFUND_STATE", dialogForm.refundState) }}
            <i class="el-icon-refresh"></i>
          </div>
        </div>
        <div class="order_amount_css" >
          <div class="amount_css">
            <div class="left_css">退款金额</div>
            <div class="right_css1"><span class="inner_css2" >￥{{dialogForm.refundAmount}}</span></div>
          </div>
          <div class="amount_css">
            <div class="left_css">退款时间</div>
            <div class="right_css1">{{ dialogForm.completeTime }}</div>
          </div>
          <div class="amount_css">
            <div class="left_css">退款方式</div>
            <div class="right_css2">{{ $setDictionary("DT_PAYMENT_TYPE", dialogForm.paymentType) }}</div>
          </div>
        </div>
        <div class="order_refund_css" >
          <div class="row_last_css" >
            <div class="">退款原因</div>
            <div class="refund_reason_css">{{ dialogForm.refundReason }}</div>
          </div>
        </div>
        <div class="order_line_css" ></div>
        <div class="order_payment_css">
          <div class="payment_css">
            <div class="left_css" >订单编号</div>
            <div class="right_css">{{dialogForm.orderSn}}</div>
          </div>
          <div class="payment_css">
            <div class="left_css" >支付时间</div>
            <div class="right_css">{{dialogForm.paymentTime}}</div>
          </div>
          <div class="payment_css">
            <div class="left_css" >支付金额</div>
            <div class="right_css">{{dialogForm.paymentAmount}}</div>
          </div>
          <div class="payment_css">
            <div class="left_css" >支付方式</div>
            <div class="right_css">{{$setDictionary("DT_PAYMENT_TYPE", dialogForm.paymentType) }}</div>
          </div>
        </div>
        <div class="order_client_title_css">
          购买人
        </div>
        <div class="order_client_content_css" >
          <div class="client_content_css" >
            <div class="left_css" >姓名</div>
            <div class="right_css" >{{dialogForm.buyerName ? dialogForm.buyerName : '--'}}</div>
          </div>
          <div class="client_content_css">
            <div class="left_css" >手机号</div>
            <div class="right_css" >{{dialogForm.buyerMobile}}</div>
          </div>
          <div class="client_content_last_css"></div>
        </div>
        <div class="order_client_title_css" v-if="dialogForm.refundDesc" >
          注意: <span class="info_css" >{{dialogForm.refundDesc}}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageNum from "@/components/PageNum.vue"; // 分页器
import List from "@/mixins/List"; // 混入列表处理
import Empty from "@/components/Empty.vue";
export default {
  name: "manpower_order_refundList",
  components: {
    PageNum,
    Empty
  },
  mixins: [List],
  data() {
    return {
      /* 搜索条件 */
      searchForm: {
        refundSn: '',         //退款编号
        orderSn: '',          //订单编号
        refundDate: '',       //退款日期
        refundDateStart: '',  //退款日期[开始]
        refundDateEnd: '',    //退款日期[结束]
        refundState: '',      //退款状态
      },
      refundStateList: [],   //退款状态列表
      paymentTypeList: [],   //支付方式列表
      showDialog: false,     //是否打开弹框
      dialogForm: {
      },
    };
  },
  created() {
    this.getDictionary();
  },
  async mounted() {
    this.searchForm.orderSn = await this.$route.query.orderSn;
    this.getData();
  },
  methods: {
    getData(pageNum = 1) {
      // 获取列表数据
      const { refundSn, orderSn, refundState } = this.searchForm;
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if ('' !== refundSn) {
        params.refundSn = refundSn;
      }
      if ('' !== orderSn) {
        params.orderSn = orderSn;
      }
      if ('' !== refundState) {
        params.refundState = refundState;
      }
      if (this.searchForm.refundDate && this.searchForm.refundDate.length > 0){
        params.refundDateStart = this.searchForm.refundDate[0];
        params.refundDateEnd = this.searchForm.refundDate[1];
      }
      this.doFetch({
        url: "/biz/order/refund/v1/mgt/page",
        params,
        pageNum,
      }, true, 9);
    },
    // 获取字典
    getDictionary() {
      // 退款状态
      let arr1 = this.$setDictionary("DT_REFUND_STATE", "list");
      for (const key in arr1) {
        this.refundStateList.push({
          value: key,
          label: arr1[key],
        });
      }
    },
    //打开窗口
    openDialog(row) {
      this.dialogForm = {};
      this.$post("/biz/order/refund/v1/mgt/info", { "refundId": row.refundId,},
          3000, true, 9)
          .then((res) => {
            this.dialogForm = {... res.data};
            this.showDialog = true;
          }).catch(() => { });
    },
    //关闭弹框
    closeDialog() {
      this.dialogForm = {};
      this.showDialog = false;
      this.getData();
    },
    //刷新订单状态
    refresh() {
      this.$post("/biz/order/refund/v1/mgt/state", { "refundId": this.dialogForm.refundId,},
          3000, true, 9)
          .then((res) => {
            if (res.data.success) {
              this.openDialog({"refundId": this.dialogForm.refundId});
            } else {
              console.log('退款状态异常原因:', res.data);
              this.$message.error("退款单状态异常，请联系管理员");
            }
          }).catch(() => { });
    },
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-dialog__header {
  background-color: #FFFFFF;
}
::v-deep .el-dialog__header .el-dialog__title {
  margin-top: 10px;
  color: #333333;
}
::v-deep .el-dialog__header .el-dialog__close {
  color: #333333;
}
::v-deep .el-dialog__body {
  padding: 10px 24px 24px 24px;
}
.container-css {
  width: 100%;
  display:inline-flex;
  flex-direction: column;
  justify-content: flex-start;

  .order_title_css {
    padding: 10px;
    background: #F7F8FA;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    .left_css {
      .title_css {
        padding-right: 8px;
        font-weight: 600;
        color: #333333;
      }
      .context_css {
        padding-left: 8px;
        color: #666666;
      }
    }
    .right_css {
      width: 70px;
      font-weight: 500;
      color: #333333;
      line-height: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
  }
  .order_product_css {
    margin-top: 16px;
    padding: 0 12px 12px 12px;
    background: #F7F8FA;
    .product_css {
      padding-top: 12px;
      display: flex;
      justify-content: space-between;
      .left_css {
        color: #333333;
        line-height: 14px;
      }
      .right_css {
        color: #333333;
        line-height: 14px;
      }
    }
  }
  .order_amount_css {
    margin-top: 16px;
    padding: 16px 12px 0 12px;
    border-top: 2px dashed #DDDDDD;
    .amount_css {
      display: flex;
      justify-content: space-between;
      .left_css {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .right_css1 {
        padding-bottom: 12px;
        font-weight: 500;
        .inner_css1 {
          color: #666666;
        }
        .inner_css2 {
          font-weight: 500;
          color: #EF4C4C;
        }
      }
      .right_css2 {
        font-weight: 400;
      }
    }
  }
  .order_refund_css {
    margin-top: 15px;
    padding: 12px 12px 6px 12px;
    background: rgba(255,146,46,0.1);
    .row_css {
      padding-bottom: 8px;
      display: flex;
      justify-content: space-between;
      .right_refund_css {
        font-weight: 550;
        color: #EF4C4C;
      }
    }
    .row_last_css {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 8px;
      .refund_reason_css {
        color: #EF4C4C;
      }
    }

  }
  .order_line_css {
    padding: 8px 12px;
    border-bottom: 2px dashed #DDDDDD;
  }
  .order_payment_css {
    padding: 16px 12px 0 12px;
    .payment_css {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
      .left_css {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .right_css {
        color: #666666;
        font-weight: 400;
      }
    }
  }
  .order_client_title_css {
    margin: 4px 0;
    padding: 12px;
    background: #F7F8FA;
    display: inline-flex;
    justify-content: space-between;
    color: #333333;
    font-weight: 600;
    .info_css {
      font-weight: 500;
    }
  }
  .order_client_content_css {
    .client_content_css {
      padding: 12px 12px 0 12px;
      display: flex;
      justify-content: space-between;
      .left_css {
        color: #666666;
      }
      .right_css {
        color: #666666;
      }
    }
    .client_content_last_css {
      padding: 6px 12px;
    }
  }
}
</style>